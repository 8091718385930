<template>
  <div
    v-if="!loading"
    class="patient-measures-list"
  >
    <!-- PATIENT: {{ PATIENT }} -->
    <div class="row">
      <div class="col">
        <vue-good-table
          :columns="columns"
          :rows="singlePatientMeasuresList"
          style-class="vgt-table measures-list-table"
          max-height="calc(100vh - 315px)"
          :fixed-header="!currentBrowser.isExplorer"
          :sort-options="{
            enabled: true,
            initialSortBy: {field: 'due_date', type: 'desc'}
          }"
        >
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span>
              {{ getColumnLabel(props) }}
            </span>
          </template>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <ClinicianPatientMeasuresListTableItem
              :props="props"
              :first-name="patient.first_name"
            />
          </template>
          <div slot="emptystate">
            {{ $t('noResultsFound') }}
          </div>
        </vue-good-table>
      </div>
    </div>
  </div>
  <LoadingSpinner v-else />
</template>

<style>

</style>
<script>
import { mapGetters } from 'vuex'
import { Helpers } from '@/mixins/Helpers'
import { VueGoodTable } from 'vue-good-table'
import { ClinicianPatientMeasuresList } from '@/mixins/TableColumnsHelper'
import ClinicianPatientMeasuresListTableItem from '@/components/clinician/patients/ClinicianPatientMeasuresListTableItem'
import LoadingSpinner from '@/components/common/LoadingSpinner'
import { componentRefreshSingleton } from '@/mixins/ComponentRefresh/ComponentRefreshSingleton'
import { PatientHelper } from '@/mixins/PatientHelper'

export default {
  name: 'ClinicianPatientMeasuresList',
  components: {
    LoadingSpinner,
    VueGoodTable,
    ClinicianPatientMeasuresListTableItem
  },
  mixins: [Helpers, ClinicianPatientMeasuresList, PatientHelper],
  props: {
    firstName: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: true
    }
  },
  computed: {
    ...mapGetters({
      currentBrowser: 'getCurrentBrowser',
      singlePatientMeasuresList: 'getSinglePatientMeasuresList',
      patient: 'getActiveAssignment'
    }),
    currentRouteName () {
      return this.$route.name
    }
  },
  created () {
    componentRefreshSingleton.addComponentToRefresh(this.$options.name, this)
    this.getData()
  },
  destroyed () {
    this.$store.commit('respondents/GET_RESPONDENT_ASSIGNED_MEASURES_LIST', [])
    componentRefreshSingleton.removeComponentToRefresh(this.$options.name)
  },
  methods: {
    getColumnLabel (props) {
      const isNoLabelColumn = props.column.label === 'last_session_status' || props.column.label === 'actions'
      return isNoLabelColumn ? '' : props.column.label
    },
    refreshView () {
      return this.getData()
    },
    getData () {
      this.loading = true
      this.$store.dispatch('GET_RESPONDENT_ASSIGNED_MEASURES_LIST', this.$getDecodedId(this.$route.params.ccauId)).then(() => {
        this.loading = false
      })
    }
  }
}
</script>
<style lang="scss">
.body-clinician-layout .vgt-table tbody tr:hover {
  cursor: default !important;
}
.body-clinician-layout{
  .patient-measures-list{
    .row {
      margin-right: 0;
    }
    .vgt-table.measures-list-table {
      thead {
        th {
          text-transform: uppercase;
          white-space: nowrap;
          font-size: 12px;
          font-weight: 500;
          color: #0a3560;
          ::after {
            margin-left: 0px !important;
          }
        }
      }
      tbody {
        tr{
          td {
            border-right: none;
            border-left: none;
            font-size: 15px;
            color: #262f70;
            &.last_session_status{
              .incomplete {
                color: #f57723;
              }
              .completed {
                color: #7ed321;
              }
            }
            &.due,
            &.frequency,
            &.visit,
            &.completed{
              white-space: nowrap;
            }
            &.due,
            &.completed{
              text-transform: uppercase;
            }
            &.pdf-download {
              min-width: 46px;
            }
            &.alert{
              .alert-icon {
                color: #ed616a;
              }
            }
            &.frequency,
            &.visit,
            &.program,
            &.completed {
              font-weight: 300;
            }
            &.measure,
            &.type,
            &.due{
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
</style>

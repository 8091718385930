<template>
  <span
    v-if="props.column.label === 'last_session_status'"
    class="tex-align-center"
  >
    <i
      v-if="props.formattedRow[props.column.field] > 0"
      class="fas fa-circle"
      :class="{completed: props.formattedRow[props.column.field] === 1, incomplete: props.formattedRow[props.column.field] !== 1}"
    />
  </span>
  <span v-else-if="props.column.label === $t('measuresAssignedTable.due')">
    <span v-if="props.formattedRow[props.column.field]">{{ $toLocal(props.formattedRow[props.column.field], 'MMM DD, YYYY') }}</span>
  </span>
  <span v-else-if="props.column.label === $t('measuresAssignedTable.completed')">
    <span v-if="props.formattedRow[props.column.field]">{{ $toLocal(props.formattedRow[props.column.field], 'MMM DD, YYYY') }}</span>
  </span>
  <span
    v-else-if="showPDFIcon"
    class="button-pdf"
  >
    <a
      v-tooltip="getTooltip(props.row.pdf_status)"
      :class="{ disabled: isDisabled(props.row.pdf_status), failed: isFailedClass(props.row.pdf_status) }"
      @click="getDocumentUrl(props.formattedRow[props.column.field], props.row.pdf_status)"
    ><i
      class="fal"
      :class="isFailed(props.row.pdf_status)"
    />
    </a>
  </span>
  <span v-else-if="props.column.label === $t('measuresAssignedTable.measure')">
    {{ props.formattedRow[props.column.field] }}
  </span>
  <span v-else-if="props.column.label === $t('measuresAssignedTable.alert')">
    <i
      v-if="props.formattedRow[props.column.field] > 0"
      class="far alert-icon"
      :class="{'fa-check-circle': props.formattedRow[props.column.field] == 2, 'fa-exclamation-circle': props.formattedRow[props.column.field] == 1}"
    />
  </span>
  <span v-else-if="props.column.label === $t('measuresAssignedTable.visit') && [2, 4].includes(gSettings.setting_state)">
    <span v-if="props.formattedRow[props.column.field]">{{ $toLocal(props.formattedRow[props.column.field], 'hh:mm A') }}</span>
  </span>
  <span v-else-if="props.column.label === $t('measuresAssignedTable.frequency')">
    {{ props.formattedRow[props.column.field] }}
  </span>
  <span v-else-if="props.column.label === $t('measuresAssignedTable.type')">
    {{ props.formattedRow[props.column.field] }}
  </span>
  <span v-else-if="props.column.label === $t('measuresAssignedTable.program') && gSettings.programs_enabled">
    {{ props.formattedRow[props.column.field] }}
  </span>
  <span v-else-if="props.column.label === 'Actions'">
    {{ props.formattedRow[props.column.field] }}
  </span>
</template>

<style>

</style>
<script>
import axios from 'axios'
import { Helpers } from '@/mixins/Helpers'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import { mapGetters } from 'vuex'
import { ENDPOINTS } from '@/store/modules/patients/constants/'

const PDF_STATUS_SUCCESSFUL = 'successful'
const PDF_STATUS_PENDING = 'pending'
const PDF_STATUS_FAILED = 'failed'
// eslint-disable-next-line no-unused-vars
const PDF_STATUS_NONE = 'none'

export default {
  name: 'ClinicianPatientMeasuresListTableItem',
  mixins: [DateTimeHelper, Helpers],
  props: ['props', 'firstName'],
  computed: {
    ...mapGetters({
      gSettings: 'generalSettings',
      singlePatientMeasuresList: 'getSinglePatientMeasuresList'
    }),
    showPDFIcon () {
      return this.props.row.measure_date_completed !== null &&
      this.props.row.auto_complete === 0 &&
      this.props.column.label === this.$t('measuresAssignedTable.pdf') &&
      (this.props.row.pdf_status === PDF_STATUS_SUCCESSFUL ||
      this.props.row.pdf_status === PDF_STATUS_PENDING ||
      this.props.row.pdf_status === PDF_STATUS_FAILED)
    }
  },
  methods: {
    getDocumentUrl (docId, pdfStatus) {
      if (pdfStatus === PDF_STATUS_SUCCESSFUL) {
        // Make call for PDF URL
        const url = ENDPOINTS.GET_PDF_URL.replace('{pdf_uuid}', docId)
        axios.get(url)
          .then(function (response) {
            // handle success
            const pdfLinkString = response.data
            const pdfUrl = pdfLinkString.replace(/\\\//g, '/')
            window.open(pdfUrl, '_blank')
          })
          .catch(function (error) {
            // handle error
            // eslint-disable-next-line no-console
            console.log(error)
          })
      }
    },
    isDisabled (pdfStatus) {
      return pdfStatus === PDF_STATUS_PENDING
    },
    isFailedClass (pdfStatus) {
      return pdfStatus === PDF_STATUS_FAILED
    },
    isFailed (pdfStatus) {
      return pdfStatus === PDF_STATUS_FAILED ? 'fa-exclamation-triangle' : 'fa-file-pdf'
    },
    getTooltip (pdfStatus) {
      const isDisabled = this.isDisabled(pdfStatus)
      let content = this.$t('measuresAssignedTable.pdfTooltipEnabled', { patientName: this.firstName })
      if (isDisabled) {
        content = this.$t('measuresAssignedTable.pdfTooltipDisabled')
      } else if (pdfStatus === PDF_STATUS_FAILED) {
        content = this.$t('measuresAssignedTable.pdfTooltipFailed')
      } else if (this.props.row.is_safety_plan) {
        content = this.$t('measuresAssignedTable.pdfTooltipSafetyPlan', { patientName: this.firstName })
      }
      return { boundariesElement: 'body', content: content }
    }
  }
}
</script>
